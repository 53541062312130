export default [{
        title: 'Inicio',
        route: 'home',
        icon: 'HomeIcon'
    },
    {
        title: 'Reservas',
        icon: 'CheckCircleIcon',
        children: [{
            title: 'Lista',
            route: 'bookings'
        }, {
            title: 'Agregar',
            route: 'booking-create'
        }, {
            title: 'Calendario',
            route: 'bookings-calendar'
        }]
    },
    {
        title: 'Cupones de descuento',
        icon: 'PaperclipIcon',
        children: [{
            title: 'Lista',
            route: 'coupons'
        }, {
            title: 'Agregar',
            route: 'coupons-create'
        }]
    },
    {
        title: 'Doctores',
        icon: 'ActivityIcon',
        children: [{
            title: 'Lista',
            route: 'doctors'
        }, {
            title: 'Agregar',
            route: 'doctors-create'
        }]
    },
    {
        title: 'Encuestas',
        icon: 'MessageSquareIcon',
        children: [{
            title: 'Lista',
            route: 'surveys'
        }]
    },
    {
        title: 'Noticias',
        icon: 'SidebarIcon',
        children: [{
            title: 'Lista',
            route: 'notices'
        }, {
            title: 'Agregar',
            route: 'notices-create'
        }]
    }
]